import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import TopMenu from "./components/TopMenu";
import Choose from "./components/Choose";
import Footer from "./components/Footer";
import Contactus from "./components/Contactus";
import { Routes, Route } from "react-router-dom";
import It from "./components/It";
import Hosting from "./components/Hosting";
import Mecanic from "./components/Mecanic";

import R_and_D from "./components/R_and_D";

import Electronic from "./components/Electronic";
import Architect from "./components/Architect";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Mecanic1 from "./components/Mecanic/Mecanic1";
import Mecanic2 from "./components/Mecanic/Mecanic2";
import Mecanic3 from "./components/Mecanic/Mecanic3";
import Mecanic4 from "./components/Mecanic/Mecanic4";
import License from "./components/License";
import Ticket from "./components/Ticket";
import React from "react";

 
function App() {

  return (
    <div className="d-flex flex-column text-light w-100 align-items-center" id="bg">
      <div id="shadowonbg"></div>
 
      

      <TopMenu />

      <Routes>
        <Route path="/" element={<Choose />} />

        
 
        <Route path="R&D" element={<R_and_D />} />
        <Route path="R&D/electronic" element={<Electronic />} /> 
        <Route path="R&D/architect" element={<Architect />} />
        <Route path="R&D/mecanic" element={<Mecanic />} />
        <Route path="R&D/mecanic/section1" element={<Mecanic1 />} />
        <Route path="R&D/mecanic/section2" element={<Mecanic2 />} />
        <Route path="R&D/mecanic/section3" element={<Mecanic3 />} />
        <Route path="R&D/mecanic/section4" element={<Mecanic4 />} />

        <Route path="it" element={<It />} />
        <Route path="hosting" element={<Hosting />} />
        <Route path="license" element={<License />} />
        <Route path="aboutus_contactus" element={<Contactus />} />
        <Route path="ticket" element={<Ticket />} />

      </Routes>
      <Footer />
    </div>
  );
}

export default App;
