import React from "react";
import { Link } from "react-router-dom";
import it1 from "./images/it1.jpg";

const Hosting = () => {
  return (
    <div style={{ marginTop: "35vh" }} className="w-100 d-flex justify-content-center" id="itContainer">
      <div className="w-75 my-5 rounded shadow d-flex flex-column " style={{ backgroundColor: "#F6F7F9" }} id="itContainerchild">
        <div className="d-flex w-100 text-end h-100 flex-row justify-content-start align-items-start p-5" dir="rtl" id="p0">
          <div className="mb-3 w-50" id="childw1002">
            <img src={it1} className="rounded shadowimage" width="100%" height="230px" alt="Logo" id="shadowimage" />
          </div>
          <div className="fa-it-info w-40 me-auto" id="childw1002">
            شما می توانید خدمات مرکز داده را از آدرس زیر مشاهده کنید
            <br />
            <div className="mx-auto w-100 text-center my-5" id="my0">
              <a className="fs-2" target="blank" href="https://golestanhost.net">
                DataCenter.tlh.co.ir
              </a>
            </div>
            <br />
          </div>
        </div>
        <div className="w-100 mb-3 p-5" id="bgelectronic">
          <div id="test2" className="text-center fs-2">
            نیاز به مشاوره دارید؟
            <br />
            با بخش فروش ما تماس بگیرید
            <br />
            <Link to="/ticket" className="btn btn-outline-primary text-light btn-lg mt-3">
              تماس
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hosting;
