import React from "react";
import it3 from "./images/arc/inarc/3.png";
import it4 from "./images/arc/inarc/4.png";
import it5 from "./images/arc/inarc/5.png";
import it6 from "./images/arc/inarc/6.png";
import it7 from "./images/arc/inarc/7.png";
import it8 from "./images/arc/inarc/8.png";
import it9 from "./images/arc/plan/1.png";
import it10 from "./images/arc/plan/2.png";
import it14 from "./images/arc/siteplan/1.png";
import it15 from "./images/arc/siteplan/2.png";
import it20 from "./images/arc/resume/1.jpg";
import it21 from "./images/arc/resume/2.jpg";
import it22 from "./images/arc/resume/3.jpg";
import it23 from "./images/arc/resume/4.jpg";
import it24 from "./images/arc/resume/5.jpg";
import it25 from "./images/arc/resume/6.jpg";
import it26 from "./images/arc/resume/7.jpg";
import it27 from "./images/arc/resume/8.jpg";
import it28 from "./images/arc/resume/9.jpg";
import it29 from "./images/arc/resume/10.jpg";
import it30 from "./images/arc/resume/11.jpg";
import it31 from "./images/arc/resume/12.jpg";
import it32 from "./images/arc/resume/13.jpg";
import it33 from "./images/arc/resume/14.jpg";

import { Link } from "react-router-dom";
import Slider from "react-slick";
const ArchitectDetail = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 1000,
    centerMode: true,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  const settings2 = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <div style={{ marginTop: "35vh" }} className="w-100 d-flex justify-content-center" id="itContainer">
      <div className="w-75 my-5 rounded shadow d-flex flex-column " style={{ backgroundColor: "#F6F7F9" }} id="itContainerchild">
        <div className="d-flex w-100 text-end h-100 flex-row justify-content-start align-items-start p-5" dir="rtl" id="p0">
          <Link
            to="/R&D/"
            className="p-2 mb-auto position-absolute rounded-circle border-info text-light bg-dark text-center d-flex justify-content-center align-items-center ms-auto"
            style={{ width: "35px", height: "35px" }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi mt-1 bi-arrow-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
            </svg>
          </Link>

          <div style={{ color: "#0045A6", fontSize: "25px", fontWeight: "600" }} className="mb-3 me-5 w-25 text-center">
            معماری
          </div>
          <div className="text-secondary fw-bold lh-lg">
            شرکت طلوع لوتوس هیرکانیان با همکارانی مجرب و با تجربه در بخش معماری قادر است شمارا در بخش های
            <br />
            طراحی پلان , سایت پلان , طراحی دکوراسیون داخلی با نرم افزار های 3Dmax , Revit , AutoCad همراهی کند .
          </div>
        </div>
        <div className="d-flex flex-row w-100 justify-content-center align-items-start mb-5" id="itcontent">
          <div className="p-2 d-flex flex-column w-30 justify-content-end align-items-center text-center" id="sliderarc">
            <Slider {...settings2} className="w-95 mx-auto">
              <div className="w-85">
                <img src={it3} className="rounded" width="100%" height="240px" alt="Logo" />
              </div>
              <div className="w-85 mx-3 ">
                <img src={it4} className="rounded" width="100%" height="240px" alt="Logo" />
              </div>
              <div className="w-85 mx-3 ">
                <img src={it5} className="rounded" width="100%" height="240px" alt="Logo" />
              </div>
              <div className="w-85 mx-3 ">
                <img src={it6} className="rounded" width="100%" height="240px" alt="Logo" />
              </div>
              <div className="w-85 mx-3 ">
                <img src={it7} className="rounded" width="100%" height="240px" alt="Logo" />
              </div>
              <div className="w-85 mx-3 ">
                <img src={it8} className="rounded" width="100%" height="240px" alt="Logo" />
              </div>
            </Slider>
            <div className="my-3 fa-it" id="mt5res">
              طراحی داخلی
            </div>
            <div className="fa-it-info text-wrap">
              {" "}
              دکوراسیون داخلی
              <br /> نورپردازی
              <br />
              طراحی آشپزخانه
            </div>
          </div>
          <div className="p-2 d-flex flex-column w-30 justify-content-end align-items-center text-center" id="sliderarc">
            <Slider {...settings2} className="w-95 mx-auto">
              <div className="w-85 ">
                <img src={it9} className="rounded" width="100%" height="240px" alt="Logo" />
              </div>
              <div className="w-85 mx-3 ">
                <img src={it10} className="rounded" width="100%" height="240px" alt="Logo" />
              </div>
            </Slider>
            <div className="my-3 fa-it" id="mt5res">
              {" "}
              طراحی پلان
            </div>
            <div className="fa-it-info text-wrap">
              پلان
              <br />
              نما
              <br />
              برش
              <br />
              سایت پلان
              <br />
              پرستکتیو
            </div>
          </div>
        </div>
        <div className="px-5 pb-3 text-end text-info fw-bold fs-5">نمونه کارها</div>
        <div className="text-dark">
          <Slider {...settings} className="w-95 mx-auto mb-5">
            <div className="w-85 ">
              <img src={it20} className="rounded" width="100%" height="240px" alt="Logo" />
            </div>
            <div className="w-85 ">
              <img src={it21} className="rounded" width="100%" height="240px" alt="Logo" />
            </div>
            <div className="w-85 ">
              <img src={it22} className="rounded" width="100%" height="240px" alt="Logo" />
            </div>
            <div className="w-85 ">
              <img src={it23} className="rounded" width="100%" height="240px" alt="Logo" />
            </div>
            <div className="w-85 ">
              <img src={it24} className="rounded" width="100%" height="240px" alt="Logo" />
            </div>
            <div className="w-85 ">
              <img src={it25} className="rounded" width="100%" height="240px" alt="Logo" />
            </div>
            <div className="w-85 ">
              <img src={it26} className="rounded" width="100%" height="240px" alt="Logo" />
            </div>
            <div className="w-85 ">
              <img src={it27} className="rounded" width="100%" height="240px" alt="Logo" />
            </div>
            <div className="w-85 ">
              <img src={it28} className="rounded" width="100%" height="240px" alt="Logo" />
            </div>
            <div className="w-85 ">
              <img src={it29} className="rounded" width="100%" height="240px" alt="Logo" />
            </div>
            <div className="w-85 ">
              <img src={it30} className="rounded" width="100%" height="240px" alt="Logo" />
            </div>
            <div className="w-85 ">
              <img src={it31} className="rounded" width="100%" height="240px" alt="Logo" />
            </div>
            <div className="w-85 ">
              <img src={it32} className="rounded" width="100%" height="240px" alt="Logo" />
            </div>
            <div className="w-85 ">
              <img src={it33} className="rounded" width="100%" height="240px" alt="Logo" />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default ArchitectDetail;
