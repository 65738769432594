
import React, { useEffect, useRef } from "react";
import Hamburger from "./Hamburger";
import $ from "jquery";
import { Link } from "react-router-dom";
const TopMenu = () => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          $("#menu").hide(1000);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  return (
    <div className="w-100 " style={{ zIndex: "2" }}>
      <div id="menu" ref={wrapperRef}>
        <Hamburger />
      </div>
      <div className="d-flex flex-row w-60 mt-3 p-3 mx-auto align-items-center" id="removebg">
        <div className="me-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="currentColor" className="bi bi-globe-americas" viewBox="0 0 16 16">
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM2.04 4.326c.325 1.329 2.532 2.54 3.717 3.19.48.263.793.434.743.484-.08.08-.162.158-.242.234-.416.396-.787.749-.758 1.266.035.634.618.824 1.214 1.017.577.188 1.168.38 1.286.983.082.417-.075.988-.22 1.52-.215.782-.406 1.48.22 1.48 1.5-.5 3.798-3.186 4-5 .138-1.243-2-2-3.5-2.5-.478-.16-.755.081-.99.284-.172.15-.322.279-.51.216-.445-.148-2.5-2-1.5-2.5.78-.39.952-.171 1.227.182.078.099.163.208.273.318.609.304.662-.132.723-.633.039-.322.081-.671.277-.867.434-.434 1.265-.791 2.028-1.12.712-.306 1.365-.587 1.579-.88A7 7 0 1 1 2.04 4.327Z" />
          </svg>
        </div>
        <Link to="/" className="font-weight-bold text-center text-light" style={{ fontSize: "20px" }}>
          شرکت طلوع
          <br /> لوتوس هیرکانیان
        </Link>

        <div className="d-flex flex-row ms-auto justify-content-center align-items-center">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" style={{ color: "#A6B9C2" }} className="bi bi-telephone-plus" viewBox="0 0 16 16">
              <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
              <path fillRule="evenodd" d="M12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5z" />
            </svg>
          </div>
          <Link to="aboutus_contactus" className="d-flex flex-column ms-3 align-items-center">
            <div className="headtitle">021-91305425</div>
            <div className="childtitle">با ما در تماس باشید</div>
          </Link>
        </div>
        <a href="https://goo.gl/maps/VMteQy8M4yUW6MM48" target="blank" className="d-flex flex-row ms-4 justify-content-center align-items-center">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" style={{ color: "#A6B9C2" }} className="bi bi-pin-map" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8l3-4z" />
              <path fillRule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z" />
            </svg>
          </div>
          <div className="d-flex flex-column ms-3 align-items-center" id="findusres">
            <div className="headtitle">ما را در گوگل پیدا کنید</div>
            <div className="childtitle">با ما در ارتباط باشید</div>
          </div>
        </a>
        <button onClick={() => $("#menu").css("display", "block")} id="hamburgermenu">
          <svg xmlns="http://www.w3.org/2000/svg" width="35" height="26" fill="white" className="bi bi-list" viewBox="0 0 16 16">
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </button>
      </div>
      <div className="w-60 mx-auto d-flex flex-row align-items-center justify-content-center text-nowrap" id="hovertopdiv" dir="rtl">
        <div className="d-flex flex-row px-5 my-2 align-items-center" id="borderleft">
          <Link to="/" className="d-flex flex-column">
            <div className="headtitle">خانه</div>
            <div className="childtitle">صفحه اصلی</div>
          </Link>
        </div>
        <div className="d-flex flex-row px-5 my-2 align-items-center" id="borderleft">
          <Link to="R&D" className="d-flex flex-column">
            <div className="headtitle">توسعه و تحقیقات</div>
            <div className="childtitle">معماری , مکانیک , الکترونیک</div>
          </Link>
        </div>
        <div className="d-flex flex-row px-5 my-2 align-items-center" id="borderleft">
          <Link to="it" className="d-flex flex-column">
            <div className="headtitle ">برنامه نویسی</div>
            <div className="childtitle">طراحی سایت و اپلیکیشن</div>
          </Link>
        </div>
        <div className="d-flex flex-row px-5 my-2 align-items-center" id="borderleft">
          <Link to="hosting" className="d-flex flex-column">
            <div className="headtitle">مرکز داده</div>
            <div className="childtitle">فروش سرور و دامنه</div>
          </Link>
        </div>
        <div className="d-flex flex-row px-5 my-2 align-items-center" id="borderleft">
          <Link to="license" className="d-flex flex-column">
            <div className="headtitle">مجوز ها</div>
            <div className="childtitle">رزومه و نمونه کار</div>
          </Link>
        </div>
        <div className="d-flex flex-row px-5 my-2 align-items-center">
          <Link to="aboutus_contactus" className="d-flex flex-column">
            <div className="headtitle">درباره ی ما</div>
            <div className="childtitle"> درباره شرکت و تماس </div>
          </Link>
        </div>
      </div>

      <div id="topmenutitle" className="w-100 h-100 text-center d-flex flex-column align-items-center justify-content-center">
        <div style={{ fontSize: "20px", fontWeight: "500" }}>پروژه های خود را</div>
        <div style={{ fontSize: "27px", fontWeight: "700" }}>به متخصصین تیم ما بسپارید</div>
        <div className="d-flex flex-row justify-content-center align-items-center">
          <Link to="/aboutus_contactus" className="mt-4 px-5 py-3" style={{ fontSize: "17px" }} id="topbutton">
            سفارش پروژه
          </Link>
          <Link to="/ticket" className="mt-4 px-5 py-3 mx-2" style={{ fontSize: "18px" }} id="topbutton2">
            <div className="ms-4">پشتیبانی</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TopMenu;
