import React, { useState, useEffect } from "react";
import teamwork from "./images/teamwork.png";
import teamwork2 from "./images/experience.png";
import teamwork3 from "./images/knowledge.png";
import it1 from "./images/it.jpg";
import it2 from "./images/it2.jpg";
import $ from "jquery";
import { Link } from "react-router-dom";

const Choose = () => {
  const [knowledge, setKnowlege] = useState(1);
  const [time, setTime] = useState(1);
  const [firstcolor, setFirstcolor] = useState("#AABCC4");
  const [secondcolor, setSecondcolor] = useState("#AABCC4");
  const [thirdcolor, setThirdcolor] = useState("#AABCC4");

  useEffect(() => {
    if (knowledge === 2) {
      setKnowlege(0);
    } else {
      setKnowlege(knowledge + 1);
    }
  }, [time]);

  useEffect(() => {
    if (knowledge === 0) {
      setFirstcolor("#0C77FF");
      $("#show1").show();
      $("#show2").hide();
      $("#show3").hide();
      setSecondcolor("#AABCC4");
      setThirdcolor("#AABCC4");
    } else if (knowledge === 1) {
      setSecondcolor("#0C77FF");
      $("#show1").hide();
      $("#show2").show();
      $("#show3").hide();
      setFirstcolor("#AABCC4");
      setThirdcolor("#AABCC4");
    } else if (knowledge === 2) {
      setThirdcolor("#0C77FF");
      setFirstcolor("#AABCC4");
      $("#show1").hide();
      $("#show2").hide();
      $("#show3").show();
      setSecondcolor("#AABCC4");
    } else {
      <></>;
    }
  }, [knowledge]);

  return (
    <div style={{ marginTop: "40vh" }} id="itContainer" className="d-flex flex-column justify-content-center align-items-center w-100 text-dark">
      <div style={{ color: "#0045A6", fontSize: "25px", fontWeight: "600" }}>چرا مارا انتخاب کنید؟</div>
      <div id="rangercontainer" className="d-flex flex-row justify-content-between w-25 mt-3 text-center" style={{ fontSize: "14px", fontWeight: "800" }}>
        <div className="" onClick={() => setKnowlege(0)} style={{ color: `${firstcolor}` }}>
          قیمت <br />
          مناسب
        </div>
        <div className="" onClick={() => setKnowlege(1)} style={{ color: `${secondcolor}` }}>
          تجربیات <br />
          
        </div>
        <div className="" onClick={() => setKnowlege(2)} style={{ color: `${thirdcolor}` }}>
          برخی از <br />
          سرویس ها
        </div>
      </div>
      <div className="w-25 mt-2  lh-lg" id="rangercontainer">
        <input type="range" onChange={(e) => setKnowlege(parseInt(e.target.value, 10))} value={knowledge} className="form-range" min="0" max="2" step="1" id="customRange3" />
      </div>
      <div id="static" className="pb-4 w-100">
        <div id="show1" className="w-60 mx-auto">
          <div className="mt-3 d-flex flex-row w-100 mx-auto mb-3 align-items-center " dir="rtl">
            <div className="rounded w-45 ">
              <img src={teamwork} id="shadowimage3" style={{ borderTopLeftRadius: "40px", borderBottomLeftRadius: "40px", borderBottomRightRadius: "40px" }} width="100%" height="250px" alt="Logo" />
            </div>
            <div className=" d-flex mx-5 flex-column w-70">
              <div className="text-dark  mb-3 fw-bold fs-5  py-4"> فروش و خدمات </div>
              <div
                style={{
                  color: "#758992",
                  fontWeight: "600",
                  fontSize: "13px",
                }}
                id="txcenter"
              ></div>
              <div
                className="mt-2 lh-lg d-flex align-items-start"
                style={{
                  color: "#758992",
                  fontWeight: "700",
                  fontSize: "15px",
                }}
              >
                کیفیتی که انتظار داشتید و قیمتی که انتظار نداشتید را با ما تجربه کنید
                <br />
                خدمات و پشتیبانی های متنوعی در اختیار شما قرار خواهد گرفت
              </div>
            </div>
          </div>
        </div>
        <div id="show2" className="w-60 mx-auto">
          <div className="mt-3 d-flex flex-row w-100 mx-auto mb-3 align-items-center " dir="rtl">
            <div className="rounded w-45 ">
              <img src={teamwork2} id="shadowimage4" style={{ borderTopLeftRadius: "40px", borderBottomLeftRadius: "40px", borderBottomRightRadius: "40px" }} width="100%" height="250px" alt="Logo" />
            </div>
            <div className=" d-flex mx-5 flex-column w-70 py-4">
              <div className="text-dark  mb-3 fw-bold fs-5">تجربیات شرکت</div>
              <div
                style={{
                  color: "#758992",
                  fontWeight: "600",
                  fontSize: "13px",
                }}
                id="txcenter"
              ></div>
              <div
                className="mt-2 lh-lg"
                style={{
                  color: "#758992",
                  fontWeight: "700",
                  fontSize: "15px",
                }}
              >
                شرکت طلوع لوتوس هیرکانیان با داشتن دانش و متخصصین حرفه ای و با تجربه
                <br />
                در زمینه های الکترونیک,معماری,مکانیک و برنامه نویسی در کنار شماست تا
                <br />
                در این امر به شما خدمات تخصصی و حرفه ای ارائه دهد.
              </div>
            </div>
          </div>
        </div>
        <div id="show3" className="w-60 mx-auto">
          <div className="mt-3 d-flex flex-row w-100 mx-auto mb-3 align-items-center " dir="rtl">
            <div className="rounded w-45 ">
              <img src={teamwork3} id="shadowimage5" style={{ borderTopLeftRadius: "40px", borderBottomLeftRadius: "40px", borderBottomRightRadius: "40px" }} width="100%" height="250px" alt="Logo" />
            </div>
            <div className=" d-flex mx-5 flex-column w-70 py-4 px-2">
              <div className="text-dark  fw-bold fs-5 "> برخی از سرویس ها</div>

              <div
                className="mt-2 lh-lg"
                style={{
                  color: "#758992",
                  fontWeight: "700",
                  fontSize: "15px",
                }}
              >
                برنامه نویسی و طراحی سایت , طراحی انواع مدارهای الکترونیکی
                <br />
                انجام پروژه های معماری , رسم نقشه و مدل سازی فضا با نرم افزارهای روز دنیا
                <br />
                ساخت و تولید انواع قطعات مکانیکی , شبیه سازی فنی , بازرسی و کنترل کیفیت
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-100 d-flex justify-content-center align-items-center " style={{ backgroundColor: "#F3F5F8" }} dir="rtl" id="mt-selectres">
        <div className="my-10 d-flex flex-row w-85 justify-content-between  align-items-start" id="co-container">
          <div className="d-flex flex-column w-30 ms-5 " id="w-95res">
            <img src={it1} className="rounded shadowimage" width="100%" alt="Logo" id="shadowimage2" />
            <Link to="ticket" className="mt-4 mb-2 fa-it fs-5 ">پشتیبانی </Link>
            <div className="fa-it-info text-wrap d-flex flex-row">
              همکاران ما در بخش پشتیبانی آماده پاسخگویی
              <br />
              به سوالات و مشکلات شما مشتریان عزیز می باشند
              <br />
            </div>
          </div>
          <div className="d-flex flex-column w-30 ms-5" id="w-95res">
            <img src={it2} className="rounded shadowimage" width="100%" alt="Logo" id="shadowimage2" />
            <a href="https://golestanhost.net" className="mt-4 mb-2 fa-it fs-5">
              مرکز داده
            </a>
            <div className="fa-it-info text-wrap">
              برخی از خدمات :
              <br />
              فروش و اجاره سرور , خدمات کو لوکیشن , کلود , ثبت دامنه
            </div>
          </div>
          <div className="d-flex flex-column w-40 fa-it mt-4">
            <div style={{ color: "#0045A6", fontSize: "25px", fontWeight: "600" }} className="mb-3">
            بخش پشتیبانی شرکت
                          <br />


            </div>
            <div className="fa-it-info">
            ما افتخار داریم که برای حفظ و افزایش رضایت شما عزیزان، در هر ساعت از شبانه روز، هفته و سال، پاسخگوی سوالات، نظرات و پیشنهادات شما هستیم. .
              </div>
            <Link to="/ticket" className="mt-2 me-auto px-5 py-3 ms-5" id="clippath">
              پشتیبانی
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Choose;
