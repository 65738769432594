import React from "react";
import it1 from "./images/webdeveloper1.jpg";
import it2 from "./images/webdeveloper2.jpg";
import it3 from "./images/webdeveloper3.jpg";
import it4 from "./images/webdeveloper4.jpg";
import it5 from "./images/webdeveloper5.jpg";

const It = () => {
  return (
    <div style={{ marginTop: "35vh" }} className="w-100 d-flex flex-column justify-content-center align-items-center" id="itContainer">
      <div className="w-75 my-5 rounded shadow d-flex flex-column justify-content-center align-items-center " style={{ backgroundColor: "#F6F7F9" }} id="itContainerchild">
        <div className="p-3" style={{ fontSize: "20px", fontWeight: "600", color: "#0D87E8" }}>
          طراحی سایت و اپلیکیشن
        </div>
        <div
          dir="rtl"
          className="text-center"
          style={{
            color: "#737B8C",
            lineHeight: "28px",
            marginTop: "15px",
            fontWeight: "600",
          }}
        >
          واحد طراحی سایت طلوع لوتوس هیرکانیان بر اساس مراحل و اصول مشخص مهندسی خدمات طراحی و ساخت وبسایت و اپلیکیشن اندروید و ios تخصصی را ارائه می‌کند.
          <br />
          قبل از هر چیز طی یک جلسه مشاوره مشخص می‌شود که نیاز و انتظار کاربر از نوع وبسایت و یا اپلیکیشن مورد نظر خود چیست.
          <br />
          انواع سایت تخصصی شامل طراحی سایت املاک ، طراحی سایت پزشکی، طراحی سایت حقوقی، طراحی سایت گردشگری، دکوراسیون و طراحی فروشگاه های اینترنتی حرفه ای می‌باشد
        </div>
        <div className="w-100  mt-5 d-flex flex-row mb-4" style={{ backgroundColor: "#F6F7F9" }} id="itcontent">
          <div className="w-50 p-3 rounded ms-3 text-end d-flex flex-column " id="childw100">
            <div style={{ fontSize: "20px", fontWeight: "600", color: "#0D87E8" }}>چرا به طراحی سایت نیاز داریم ؟</div>
            <div className="d-flex flex-row justify-content-end mb-2 my-4 align-items-center">
              <div style={{ color: "#737B8C", fontWeight: "600" }}>استفاده از جدیدترین تکنولوژی‌های روز دنیا</div>
              <div className="mx-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#0D87E8" className="bi bi-check-all" viewBox="0 0 16 16">
                  <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                </svg>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-end my-2 align-items-center">
              <div style={{ color: "#737B8C", fontWeight: "600" }}>ساخت سایت حرفه ای به منزله شناسنامه کسب و کار شماست</div>
              <div className="mx-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#0D87E8" className="bi bi-check-all" viewBox="0 0 16 16">
                  <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                </svg>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-end my-2 align-items-center">
              <div style={{ color: "#737B8C", fontWeight: "600" }}>داشتن وب سایت رسمی باعث ایجاد تاثیر مثبت بر مشتریان می‌شود</div>
              <div className="mx-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#0D87E8" className="bi bi-check-all" viewBox="0 0 16 16">
                  <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                </svg>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-end my-2 align-items-center">
              <div style={{ color: "#737B8C", fontWeight: "600" }}>وب سایت با رابط کاربری و ظاهر زیبا باعث جلب توجه مشتریان می‌شود</div>
              <div className="mx-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#0D87E8" className="bi bi-check-all" viewBox="0 0 16 16">
                  <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                </svg>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-end my-2 align-items-center">
              <div style={{ color: "#737B8C", fontWeight: "600" }}>طراحی سایت ریسپانسیو در مرحله اول شما را از طراحی اپلیکیشن بی‌نیاز می‌کند</div>
              <div className="mx-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#0D87E8" className="bi bi-check-all" viewBox="0 0 16 16">
                  <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                </svg>
              </div>
            </div>
          </div>
          <div className="w-50 d-flex rounded flex-column justify-content-center align-items-center mb-3" id="childw1001">
            <img src={it1} width="70%" className="rounded-2 shadowimage" height="280" alt="Logo" id="shadowimage2" />
          </div>
        </div>
        <div className="p-3" style={{ fontSize: "20px", fontWeight: "600", color: "#0D87E8" }}>
          مراحل طراحی سایت
        </div>
        <div className="d-flex flex-row justify-content-around align-items-center w-100 mb-4" dir="rtl" id="itcontent">
          <div className="w-25 d-flex flex-column justify-content-center align-items-center" id="childw1002">
            <div className="p-3" style={{ fontSize: "16px", fontWeight: "600", color: "#0D87E8" }}>
              مرحله اول : طراحی رابط کاربری
            </div>
            <div className="w-100">
              <img src={it5} width="100%" className="rounded-2 shadowimage" height="180px" alt="Logo" id="shadowimage3" />
            </div>
          </div>
          <div className="w-25 mx-2 d-flex flex-column justify-content-center align-items-center" id="childw1002">
            <div className="p-3" style={{ fontSize: "16px", fontWeight: "600", color: "#0D87E8" }}>
              مرحله دوم : کد نویسی
            </div>
            <div className="w-100">
              <img src={it4} width="100%" className="rounded-2 shadowimage" height="180px" alt="Logo" id="shadowimage3" />
            </div>
          </div>
          <div className="w-25 mx-2 d-flex flex-column justify-content-center align-items-center" id="childw1002">
            <div className="p-3" style={{ fontSize: "16px", fontWeight: "600", color: "#0D87E8" }}>
              مرحله سوم : رفع خطا
            </div>
            <div className="w-100">
              <img src={it3} width="100%" className="rounded-2 shadowimage" height="180px" alt="Logo" id="shadowimage3" />
            </div>
          </div>
          <div className="w-25 d-flex flex-column justify-content-center align-items-center" id="childw1002">
            <div className="p-3" style={{ fontSize: "16px", fontWeight: "600", color: "#0D87E8" }}>
              مرحله چهارم : نسخه نهایی سایت
            </div>
            <div className="w-100">
              <img src={it2} width="100%" className="rounded-2 shadowimage" height="180px" alt="Logo" id="shadowimage3" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default It;
