import React from "react";
import it1 from "./images/electronic/electronic1.jpg";
import it2 from "./images/electronic/electronic2.jpg";
import it3 from "./images/electronic/electronic3.jpg";
import { Link } from "react-router-dom";
const Electronic = () => {
  return (
    <div style={{ marginTop: "35vh" }} className="w-100 d-flex justify-content-center" id="itContainer">
      <div className="w-75 my-5 rounded shadow d-flex flex-column " style={{ backgroundColor: "#F6F7F9" }} id="itContainerchild">
        <div className="d-flex w-100 text-end h-100 flex-row justify-content-start align-items-start p-5" dir="rtl" id="p0">
          <Link
            to="/R&D/"
            className="p-2 mb-auto position-absolute rounded-circle border-info text-light bg-dark text-center d-flex justify-content-center align-items-center ms-auto"
            style={{ width: "35px", height: "35px" }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi mt-1 bi-arrow-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
            </svg>
          </Link>{" "}
          <div style={{ color: "#0045A6", fontSize: "25px", fontWeight: "600" }} className="mb-3 w-25 mx-auto text-center">
            الکترونیک
          </div>
        </div>
        <div className="d-flex flex-row w-100 justify-content-center align-items-start mb-5" id="itcontent">
          <div className="p-2 d-flex flex-column w-30 justify-content-start align-items-center text-center" id="childw1002">
            <img src={it3} className="rounded shadowimage" width="100%" height="200px" alt="Logo" id="shadowimage" />
            <div className="my-3 fa-it"> طراحی انواع مدار فرمان​</div>
            <div className="fa-it-info text-wrap">
              مدار های مجتمع و قطعات مجزا
              <br />
              سیگنال های لازم برای فرمان دادن به گیت قطعات
            </div>
          </div>
          <div className="p-2 d-flex flex-column w-30 justify-content-end align-items-center text-center" id="childw1002">
            <img src={it2} className="rounded shadowimage" width="100%" height="200px" alt="Logo" id="shadowimage" />
            <div className="my-3 fa-it">طراحی انواع مدار دیجیتال </div>
            <div className="fa-it-info text-wrap">
              {" "}
              طراحی سیگنال های دیجیتال
              <br /> و مهندسی دستگاه ها
              <br />
              که سیگنال های دیجیتال استفاده یا تولید میکنند
            </div>
          </div>
          <div className="p-2 d-flex flex-column w-30 justify-content-end align-items-center text-center" id="childw1002">
            <img src={it1} className="rounded shadowimage" width="100%" height="200px" alt="Logo" id="shadowimage" />
            <div className="my-3 fa-it"> طراحی تقویت کننده ها</div>
            <div className="fa-it-info text-wrap">
              طراحی و تعمیر <br /> انواع امپلی فایرز
              <br />
              طراحی منابع تغذیه
              <br />
              طراحی فیلترها و مبدل های آنالوگ به دیجیتال
              <br />
              طراحی شکل دهنده های سیگنال
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Electronic;
