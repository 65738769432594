import React, { useState } from "react";
import axios from "axios";

const Contactus = () => {
  // ایجاد متغیرهای وضعیت برای نگهداری داده‌های ورودی
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [section, setSection] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  // ایجاد یک متغیر وضعیت برای نگهداری پاسخ سرور
  const [response, setResponse] = useState(null);

  // تابع برای ارسال پیام به سرور با استفاده از axios
  const handleSubmit = async () => {
    try {
      // ارسال درخواست post به آدرس مورد نظر با داده‌های مربوط به پیام
      const res = await axios.post("https://tlh.co.ir/api/v01/aboutus_contactus.php", {
        name: name,
        phone: phone,
        email: email,
        section: section,
        subject: subject,
        message: message,
      });
      // بررسی مقدار status در پاسخ سرور
      if (res.data.status === "ok") {
        // تنظیم متغیر وضعیت response به مقدار "پیام شما ارسال شد"
        setResponse("پیام شما ارسال شد");
      } else if (res.data.status === "error") {
        // تنظیم متغیر وضعیت response به مقدار "خطا در ارسال پیام"
        setResponse("خطا در ارسال پیام");
      }
    } catch (err) {
      // در صورت بروز خطا، نمایش پیغام خطا در کنسول
      console.error(err);
    }
  };

  return (
    <div style={{ marginTop: "40vh" }} dir="rtl" className="w-100 position-relative text-dark d-flex flex-row justify-content-center align-items-center " id="itContainer">
      <div className="d-flex flex-row w-75" id="flexcolres">
      <div className="d-flex flex-column w-50 justify-content-center align-items-start text-secondary " id="childw1002">
          
          <div className="d-flex flex-row mt-4 ">
            <div className="d-flex justify-content-center align-items-center ms-4">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#0D87E8" className="bi bi-geo-alt" viewBox="0 0 16 16">
                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
              </svg>
            </div>
            <div className="d-flex flex-column fw-bold">
              <div style={{ color: "#0D87E8" }}>آدرس دفتر گرگان</div>
              <div className="mt-3 me-3">گرگان , میدان مفتح , ابتدای کمربندی , کارخانه نوآوری گرگان</div>
              <div className="line4 mt-4"></div>
            </div>
          </div>
          <div className="d-flex flex-row mt-4 ">
            <div className="d-flex justify-content-center align-items-center ms-4">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#0D87E8" className="bi bi-geo-alt" viewBox="0 0 16 16">
                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
              </svg>
            </div>
            <div className="d-flex flex-column fw-bold">
              <div style={{ color: "#0D87E8" }}>آدرس دفتر گرگان</div>
              <div className="mt-3 me-3">گرگان , خیابان امام خمینی , روبه رو هتل خیام , آفتاب ۱۴</div>
              <div className="line4 mt-4"></div>
            </div>
          </div>
          <div className="d-flex flex-row mt-4">
            <div className="d-flex justify-content-center align-items-center ms-4">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#0D87E8" className="bi bi-telephone " viewBox="0 0 16 16">
                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
              </svg>
            </div>
            <div className="d-flex flex-column fw-bold">
              <div style={{ color: "#0D87E8" }}>تلفن تماس</div>
              <div className="mt-3  flex-column d-flex align-items-center">
                <a href="tel:02191305425" className="ms-5">
                  021-91305425
                </a>

                <a href="tel:01791015425" className="ms-5">
                  017-91015425
                </a>
              </div>

              <div className="line4 mt-4"></div>
            </div>
          </div>
          <div className="d-flex flex-row mt-4">
            <div className="d-flex justify-content-center align-items-center ms-4">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#0D87E8" className="bi bi-envelope" viewBox="0 0 16 16">
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
              </svg>
            </div>
            <div className="d-flex flex-column fw-bold">
              <div style={{ color: "#0D87E8" }}>ایمیل های شرکت</div>
              <div className="mt-3 d-flex flex-column lh-lg" dir="rtl">
                <div className=" d-flex flex-row w-100" dir="ltr">
                  <div className="fontcolor">support@tlh.co.ir</div>
                  <div className="ms-auto ps-5"> : پشتیبانی </div>
                </div>
                
                <div className=" d-flex flex-row" dir="ltr">
                  <div className="fontcolor">sales@tlh.co.ir</div>
                  <div className="ms-auto"> : فروش </div>
                </div>
                <div className=" d-flex flex-row" dir="ltr">
                  <div className="fontcolor">info@tlh.co.ir</div>
                  <div className="ms-auto"> : روابط عمومی</div>
                </div>
                <div className=" d-flex flex-row" dir="ltr">
                  <div className="fontcolor">ceo@tlh.co.ir</div>
                  <div className="ms-auto"> : مدیریت </div>
                </div>
              </div>
              <div className="line4 mt-4"></div>
            </div>
          </div>
        </div>
        {/* شرط برای نمایش یا پنهان کردن قسمت فرم */}
        {!response && (
          <div className="d-flex flex-column w-50  justify-content-center align-items-center" id="childw1002">
            <div className="w-100 d-flex justify-content-center my-2">
              <input className="w-50 form-control" placeholder="نام و نام خانوادگی" onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="w-100 d-flex justify-content-center mb-2">
              <input className="w-50 form-control" placeholder="شماره تماس" onChange={(e) => setPhone(e.target.value)} />
            </div>
            <div className="w-100 d-flex justify-content-center mb-2">
              <input className="w-50 form-control" placeholder="ایمیل شما" onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="w-100 d-flex justify-content-center mb-2">
              <select name="cars" className="p-2 w-50 form-control text-secondary " onChange={(e) => setSection(e.target.value)}>
                <option value="0" defaultValue hidden>
                  انتخاب بخش مورد نظر
                </option>
                <option value="1">مدیریت</option>
                <option value="2">پشتیبانی فنی</option>
                <option value="3">فروش</option>
                <option value="4">روابط عمومی</option>
                <option value="5">نظرات،انتقادات،پیشنهادات</option>
              </select>
            </div>
            <div className="w-100 d-flex justify-content-center mb-2">
              <input className="w-50 form-control" placeholder="موضوع" onChange={(e) => setSubject(e.target.value)} />
            </div>
            <div className="w-100 d-flex justify-content-center">
              <textarea className="w-50 form-control" placeholder="متن پیام" onChange={(e) => setMessage(e.target.value)}></textarea>
            </div>
            <button className="rounded border-0 px-3 py-2 mt-1 bg-light fw-bold" style={{ color: "#0D87E8" }} onClick={handleSubmit}>
              {" "}
              ارسال پیام
            </button>
          </div>
        )}
        {/* نمایش پاسخ سرور در صورت وجود */}
        {response && (
          <div className="d-flex flex-column w-50  justify-content-center align-items-center" id="childw1002">
            <div className="text-wrap text-end mt-3 d-flex flex-row justify-content-end">
              {response}
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

export default Contactus;
