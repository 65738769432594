import React from "react";
import Slider from "react-slick";
import it1 from "./images/license/it1.jpg";
import it2 from "./images/park.jpg";
import it20 from "./images/arc/resume/1.jpg";
import it21 from "./images/arc/resume/2.jpg";
import it22 from "./images/arc/resume/3.jpg";
import it23 from "./images/arc/resume/4.jpg";
import it24 from "./images/arc/resume/5.jpg";
import it25 from "./images/arc/resume/6.jpg";
import it26 from "./images/arc/resume/7.jpg";
import it27 from "./images/arc/resume/8.jpg";
import it28 from "./images/arc/resume/9.jpg";
import it29 from "./images/arc/resume/10.jpg";
import it30 from "./images/arc/resume/11.jpg";
import it31 from "./images/arc/resume/12.jpg";
import it32 from "./images/arc/resume/13.jpg";
import it33 from "./images/arc/resume/14.jpg";
import it40 from "./images/mecanic/mecanicResume/mecanic (1).jpg";
import it41 from "./images/mecanic/mecanicResume/mecanic (2).jpg";
import it42 from "./images/mecanic/mecanicResume/mecanic (3).jpg";
import it43 from "./images/mecanic/mecanicResume/mecanic (4).jpg";
import it44 from "./images/mecanic/mecanicResume/mecanic (5).jpg";
import it45 from "./images/mecanic/mecanicResume/mecanic (6).jpg";
import it46 from "./images/mecanic/mecanicResume/mecanic (7).jpg";
import it47 from "./images/mecanic/mecanicResume/mecanic (8).jpg";
import it48 from "./images/mecanic/mecanicResume/mecanic (9).jpg";
import it49 from "./images/mecanic/mecanicResume/mecanic (10).jpg";
import it50 from "./images/mecanic/mecanicResume/mecanic (11).jpg";
import it51 from "./images/mecanic/mecanicResume/mecanic (12).jpg";
import it52 from "./images/mecanic/mecanicResume/mecanic (13).jpg";
import it53 from "./images/mecanic/mecanicResume/mecanic (14).jpg";

const License = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  const settings3 = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  return (
    <div style={{ marginTop: "35vh" }} className="w-100 d-flex flex-column align-items-center justify-content-center" id="architectContainer">
      <div className="fa-it fs-3 fw-bold mt-5 mb-1">مجوز ها و افتخارات</div>
      <Slider {...settings} className="w-85 mx-auto mb-5">
        <a href="https://www.gstpark.ir/fa" target="_blank" className="w-85 ">
          <div className="fa-it-info text-center my-3 fs-5"> عضو پارک علم و فناوری استان گلستان</div>
          <img src={it2} className="rounded" width="100%" height="240px" alt="Logo" />
        </a>
        <a
          href="https://golestan.irannsr.org/fa/trade_unit/2426069/swd_id/106501/%D8%A7%D8%B9%D8%B6%D8%A7%DB%8C-%D8%AD%D9%82%D9%88%D9%82%DB%8C-%D8%B7%D9%84%D9%88%D8%B9-%D9%84%D9%88%D8%AA%D9%88%D8%B3-%D9%87%DB%8C%D8%B1%DA%A9%D8%A7%D9%86%DB%8C%D8%A7%D9%86.html"
          target="_blank"
          className="w-85 "
        >
          <div className="fa-it-info text-center my-3 fs-5">سازمان نظام صنفی رایانه ای کشور</div>
          <img src={it1} className="rounded" width="100%" height="240px" alt="Logo" />
        </a>
      </Slider>
      <div className="fa-it fs-3 fw-bold mt-3 mb-3"> نمونه کارهای بخش معماری</div>
      <Slider {...settings2} className="w-85 mx-auto mb-5">
        <div className="w-85 ">
          <img src={it20} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it21} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it22} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it23} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it24} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it25} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it26} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it27} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it28} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it29} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it20} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it21} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it30} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it31} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it32} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it33} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
      </Slider>
      <div className="fa-it fs-3 fw-bold mt-3 mb-3"> نمونه کارهای بخش مکانیک</div>
      <Slider {...settings2} className="w-85 mx-auto mb-5">
        <div className="w-85 ">
          <img src={it40} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it41} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it42} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it43} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it44} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it45} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it46} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it47} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it48} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it49} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it50} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it51} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it52} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
        <div className="w-85 ">
          <img src={it53} className="rounded" width="100%" height="240px" alt="Logo" />
        </div>
      </Slider>
    </div>
  );
};

export default License;
