import React from "react";
import { Link } from "react-router-dom";

const Hamburger = () => {
  return (
    <div className="d-flex flex-column align-items-center w-100 h-100 mt-4">
      <Link to="/" className="d-flex flex-row px-5 my-2 align-items-center " id="borderleft1">
        <div className="d-flex flex-column text-center">
          <div className="headtitle">خانه</div>
          <div className="childtitle">صفحه اصلی</div>
        </div>
      </Link>
      <Link to="architect" className="d-flex flex-row px-5 my-2 align-items-center" id="borderleft1">
        <div className="d-flex flex-column text-center">
          <div className="headtitle">توسعه و تحقیقات</div>
          <div className="childtitle">معماری , مکانیک , الکترونیک</div>
        </div>
      </Link>
      <Link to="it" className="d-flex flex-row px-5 my-2 align-items-center" id="borderleft1">
        <div className="d-flex flex-column text-center">
          <div className="headtitle ">برنامه نویسی</div>
          <div className="childtitle">طراحی سایت و اپلیکیشن</div>
        </div>
      </Link>
      <Link to="hosting" className="d-flex flex-row px-5 my-2 align-items-center" id="borderleft1">
        <div className="d-flex flex-column text-center">
          <div className="headtitle">مرکز داده</div>
          <div className="childtitle">فروش سرور و دامنه</div>
        </div>
      </Link>
      <Link to="license" className="d-flex flex-row px-5 my-2 align-items-center" id="borderleft1">
        <div className="d-flex flex-column text-center">
          <div className="headtitle">مجوزها</div>
          <div className="childtitle">رزومه و نمونه کار</div>
        </div>
      </Link>
      <Link to="aboutus_contactus" className="d-flex flex-row px-5 my-2 align-items-center" id="borderleft1">
        <div className="d-flex flex-column text-center">
          <div className="headtitle ">درباره ی ما</div>
          <div className="childtitle" style={{ visibility: "hidden" }}>
            dsads
          </div>
        </div>
      </Link>
      <div className="d-flex flex-row w-100 mt-3">
        <div className="d-flex flex-row me-auto justify-content-center align-items-center">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" style={{ color: "#A6B9C2" }} className="bi bi-telephone-plus" viewBox="0 0 16 16">
              <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
              <path fillRule="evenodd" d="M12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5z" />
            </svg>
          </div>
          <Link to="aboutus_contactus" className="d-flex flex-column ms-3 align-items-center">
            <div className="headtitle">+17 91305425</div>
            <div className="childtitle">با ما در تماس باشید</div>
          </Link>
        </div>
        <div className="d-flex flex-row ms-4 justify-content-center align-items-center" id="googlelinkdiv">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" style={{ color: "#A6B9C2" }} className="bi bi-pin-map" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8l3-4z" />
              <path fillRule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z" />
            </svg>
          </div>
          <div className="d-flex flex-column ms-3 align-items-center">
            <a href="https://goo.gl/maps/4pPTmbfsdeBhxaBa6" target="blank" className="headtitle">
              ما را در گوگل پیدا کنید
            </a>
            <div className="childtitle">با ما در تماس باشید</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hamburger;
