import React, { useEffect, useState } from "react";
import $ from "jquery";
import axios from "axios";
const Ticket = () => {
  const [selected, setSelected] = useState("1");
  // ایجاد متغیرهای وضعیت برای نگهداری داده‌های ورودی
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  // ایجاد یک متغیر وضعیت برای نگهداری پاسخ سرور
  const [response, setResponse] = useState(null);

  useEffect(() => {
 
    $("#firstsvg").css("display", "block");
  }, []);

  useEffect(() => {
    $("button").on("click", function () {
      $("button").removeClass("activated");
      $("button").parent().children("svg").css("display", "none");
      $(this).parent().children("svg").css("display", "block");
      $(this).addClass("activated");
    });
  }, []);

  // تابع برای ارسال پیام به سرور با استفاده از axios
  const handleSubmit = async () => {
    try {
      // ارسال درخواست post به آدرس مورد نظر با داده‌های مربوط به پیام
      const res = await axios.post("https://tlh.co.ir/api/v01/ticket.php", {
        name: name,
        phone: phone,
        email: email,
        section: selected,
        subject: subject,
        message: message,
      });
      // بررسی مقدار status در پاسخ سرور
      if (res.data.status === "ok") {
        // تنظیم متغیر وضعیت response به مقدار "پیام شما ارسال شد"
        setResponse("پیام شما ارسال شد");
      } else if (res.data.status === "error") {
        // تنظیم متغیر وضعیت response به مقدار "خطا در ارسال پیام"
        setResponse("خطا در ارسال پیام");
      }
    } catch (err) {
      // در صورت بروز خطا، نمایش پیغام خطا در کنسول
      console.error(err);
    }
  };

  return (
    <div className="w-100 d-flex justify-content-center " id="itContainer" style={{ marginTop: "35vh" }} dir="rtl">
      <div className="w-75 d-flex flex-row align-items-center text-light my-5 rounded" id="ticketres">
        <div className="text-dark w-100 d-flex align-items-center text-center justify-content-center mb-2" id="selecteddivres">
          شما در حال تماس با بخش
          <p className="my-2 text-warning fs-5 fw-bold mx-2">{selected}</p>
          هستید
        </div>

        <div className="d-flex flex-column w-40 " id="ticketbg">
          <div className="my-auto me-auto d-flex w-50 flex-column" id="selectsup">
            <div className="d-flex flex-row w-100 align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" id="firstsvg" fill="white" style={{ display: "none" }} className="mx-2 bi bi-arrow-left-circle" viewBox="0 0 16 16">
                <path
                  fill-rule="evenodd"
                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                />
              </svg>
              <button className="py-2 px-4 w-75 my-1 border-0  text-light activated" style={{ backgroundColor: "#212529" }} onClick={() => setSelected("1")}>
                مرکز داده
              </button>
            </div>

            <div className="d-flex flex-row w-100 align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" style={{ display: "none" }} className="mx-2 bi bi-arrow-left-circle" viewBox="0 0 16 16">
                <path
                  fill-rule="evenodd"
                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                  />
                </svg>
                <button className="py-2 px-4 my-1  w-75 border-0   text-light " style={{ backgroundColor: "#212529" }} onClick={() => setSelected("2")}>
                  برنامه نویسی
                </button>
              </div>
              <div className="d-flex flex-row w-100 align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" style={{ display: "none" }} className="mx-2 bi bi-arrow-left-circle" viewBox="0 0 16 16">
                  <path
                    fill-rule="evenodd"
                    d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                    />
                  </svg>
                  <button className="py-2 px-4 my-1  w-75 border-0   text-light " style={{ backgroundColor: "#212529" }} onClick={() => setSelected("3")}>
                    الکترونیک
                  </button>
                </div>
                <div className="d-flex flex-row w-100 align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" style={{ display: "none" }} className="mx-2 bi bi-arrow-left-circle" viewBox="0 0 16 16">
                    <path
                      fill-rule="evenodd"
                      d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                      />
                    </svg>
                    <button className="py-2 px-4 my-1  w-75 border-0   text-light " style={{ backgroundColor: "#212529" }} onClick={() => setSelected("4")}>
                      مکانیک
                    </button>
                  </div>
                  <div className="d-flex flex-row w-100 align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" style={{ display: "none" }} className="mx-2 bi bi-arrow-left-circle" viewBox="0 0 16 16">
                      <path
                        fill-rule="evenodd"
                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                        />
                      </svg>
                      <button className="py-2 px-4 my-1  w-75 border-0   text-light " style={{ backgroundColor: "#212529" }} onClick={() => setSelected("5")}>
                        معماری
                      </button>
                    </div>
                  </div>
                </div>
        
                <div className="d-flex flex-column w-60 p-5 text-dark" id="childw1004" style={{ backgroundColor: "#EDEFF3" }}>
                  {/* شرط برای نمایش یا پنهان کردن قسمت فرم */}
                  {!response && (
                    <div className="d-flex flex-column w-100" id="childw1002">
                      <div className="d-flex my-2 flex-row w-75 align-items-center">
                        <div className="fw-bold w-25 text-nowrap">نام و نام خانوادگی :</div>
                        <input
                          className="border-top-0 border-end-0 w-50 border-start-0 mx-4 form-control rounded-0"
                          placeholder="نام"
                          style={{ backgroundColor: "#EDEFF3", borderBottom: "2px solid #C8C9CD" }}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
        
                      <div className="d-flex my-2 flex-row w-75 align-items-center">
                        <div className="fw-bold w-25"> ایمیل :</div>
                        <input
                          className="border-top-0 border-end-0 w-50 border-start-0 mx-4 form-control rounded-0"
                          placeholder="ایمیل"
                          style={{ backgroundColor: "#EDEFF3", borderBottom: "2px solid #C8C9CD" }}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="d-flex my-2 flex-row w-75 align-items-center">
                        <div className="fw-bold w-25"> شماره تماس :</div>
                        <input
                          className="border-top-0 border-end-0 w-50 border-start-0 mx-4 form-control rounded-0"
                          placeholder="شماره تماس"
                          style={{ backgroundColor: "#EDEFF3", borderBottom: "2px solid #C8C9CD" }}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      <div className="d-flex my-2 flex-row w-75 align-items-center">
                        <div className="fw-bold w-25"> موضوع :</div>
                        <input
                          className="border-top-0 border-end-0 w-50 border-start-0 mx-4 form-control rounded-0"
                          placeholder="موضوع"
                          style={{ backgroundColor: "#EDEFF3", borderBottom: "2px solid #C8C9CD" }}
                          onChange={(e) => setSubject(e.target.value)}
                        />
                      </div>
                      <div className="d-flex my-2 flex-row w-75 align-items-center">
                        <div className="fw-bold w-25"> متن پیام :</div>
        
                        <textarea
                          className="border-top-0 py-2 border-end-0 w-50 border-start-0 mx-4 form-control rounded-0"
                          style={{ backgroundColor: "#EDEFF3", borderBottom: "2px solid #C8C9CD" }}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                      </div>
                      <button className="btn btn-secondary fw-bold text-light mx-auto mt-4" onClick={handleSubmit}>
                        ارسال
                      </button>
                    </div>
                  )}
                  {/* نمایش پاسخ سرور در صورت وجود */}
                  {response && (
                    <div className="d-flex flex-column w-100 justify-content-center align-items-center" id="childw1002">
                      <div className="text-wrap text-end mt-3 d-flex flex-row justify-content-end">{response}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        };
        
        export default Ticket;
        