import React from "react";
import it1 from "./images/mecanic/mecanicTarahi (4).jpg";
import it2 from "./images/mecanic/mecanicTolid.jpg";
import it3 from "./images/mecanic/mecanicTahlil (4).png";
import it4 from "./images/mecanic/mecanicKey (2).jpg";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const MecanicDetail = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 1000,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <div style={{ marginTop: "35vh" }} className="w-100 d-flex justify-content-center" id="itContainer">
      <div className="w-85 my-5 rounded shadow d-flex flex-column " id="itContainerchild">
        <div className="d-flex w-100 text-end h-100 flex-row justify-content-start align-items-start p-5" dir="rtl" id="p0">
          <Link
            to="/R&D/"
            className="p-2 mb-auto position-relative rounded-circle border-info text-light bg-dark text-center d-flex justify-content-center align-items-center ms-auto"
            style={{ width: "35px", height: "35px" }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi mt-1 bi-arrow-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
            </svg>
          </Link>

          <div style={{ color: "#0045A6", fontSize: "25px", fontWeight: "600" }} className="mb-3 w-25 text-center" id="childw1002">
            طراحی
            <br />
            صنعتی و مکانیک
          </div>
          <div className="fa-it-info w-85 mx-auto text-center my-auto" style={{ fontSize: "15px" }}>
            شرکت طلوع لوتوس هیرکانیان با همکارانی مجرب و فارق التحصیل از دانشگاه صنعتی شریف <br /> و متخصص در زمینه مهندسی مکانیک به مشتریان خود کمک می کند تا بتوانند محصولات , خدمات و برند خود را
            قویتر از گذشته در بازار ارایه نمایند و <br /> هزینه‌های تولید را به نسبت ارزشی که خلق می‌کنند، کاهش دهند.
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center align-items-center" id="w-95res" dir="rtl">
          <Slider {...settings} className="w-100 mb-5">
            <div to="/R&D/mecanic/section1" className=" d-flex w-85 rounded flex-column mx-auto text-center" id="childw100" dir="ltr">
              <img src={it1} className="rounded" width="100%" height="200px" alt="Logo" />
              <div className=" fa-it px-2 lh-lg my-auto" style={{ fontSize: "12px", color: "rgb(175, 225, 275)" }}>
                قبول سفارشات و خدمات فنی مهندسی و نقشه کشی
              </div>
              <Link to="/R&D/mecanic/section1" className="mt-auto " id="btnmecanic">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left me-2" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                </svg>
                بیشتر بدانید
              </Link>
            </div>
            <div className="d-flex w-85 rounded flex-column mx-auto text-center" id="childw100" dir="ltr">
              <img src={it2} className="rounded" width="100%" height="200px" alt="Logo" />
              <div className="  fa-it px-2 lh-lg my-auto" style={{ fontSize: "12px", color: "rgb(175, 225, 275)" }}>
                ساخت و تولید انواع قطعات و تجهیزات
              </div>
              <Link to="/R&D/mecanic/section2" className="mt-auto" id="btnmecanic">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left me-2" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                </svg>
                بیشتر بدانید
              </Link>
            </div>
            <div className="d-flex w-85 rounded flex-column mx-auto text-center" id="childw100" dir="ltr">
              <img src={it3} className="rounded" width="100%" height="200px" alt="Logo" />
              <div className="  px-2 fa-it my-auto" style={{ fontSize: "12px", color: "rgb(175, 225, 275)" }}>
                شبیه سازی های فنی و تخصصی با نرم افزار های تحلیلی
              </div>
              <Link to="/R&D/mecanic/section3" className="mt-auto" id="btnmecanic">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left me-2" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                </svg>
                بیشتر بدانید
              </Link>
            </div>
            <div className="d-flex w-85 rounded flex-column mx-auto text-center" id="childw100" dir="ltr">
              <img src={it4} className="rounded " width="100%" height="200px" alt="Logo" />
              <div className="  px-2 my-auto fa-it" style={{ fontSize: "12px", color: "rgb(175, 225, 275)" }}>
                بازرسی و کنترل کیفیت قطعات
              </div>
              <Link to="/R&D/mecanic/section4" className="mt-auto" id="btnmecanic">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left me-2" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                </svg>
                بیشتر بدانید
              </Link>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default MecanicDetail;
