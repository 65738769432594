import React from "react";
import it1 from "./images/arc/arc.jpg";
import it2 from "./images/electronic/electronic5.png";
import it3 from "./images/mecanic/mecanicKey (2).jpg";
import { Link } from "react-router-dom";

const R_and_D = () => {
  return (
    <div style={{ marginTop: "35vh" }} className="w-100 d-flex flex-column align-items-center justify-content-center" id="architectContainer">
      <div className="w-75 mt-5 rounded shadow d-flex flex-row " style={{ backgroundColor: "#F6F7F9" }} id="architectContainerchild">
        <div className="w-50 w-100res rounded-top d-flex flex-column justify-content-start align-items-start" id="childw10011">
          <img src={it2} className="rounded-top" width="100%" height="380px" alt="Logo" id="shadowimage" />
        </div>
        <div className="w-50 text-end p-4 d-flex flex-column " id="w-100res">
          <div style={{ fontSize: "20px", fontWeight: "600", color: "#0D87E8" }}>الکترونیک</div>
          <div
            style={{
              color: "#737B8C",
              lineHeight: "32px",
              fontWeight: "600",
              marginTop: "15px",
            }}
          >
            طراحی انواع مدار آنالوگ
            <br />
            طراحی انواع مدار دیجیتال
            <br />
            طراحی انواع مدار فرمان
            <br />
            طراحی انواع مدار با نرم افزار های
          </div>
          <Link to="electronic" className="btn w-15 me-auto btn-outline-primary fw-bold mt-auto">
            ادامه مطلب
          </Link>
        </div>
      </div>
      <div className="w-75  shadow d-flex flex-row " style={{ backgroundColor: "#F6F7F9" }} id="architectContainerchild">
        <div id="w-100res" className="w-50 text-end p-4 d-flex flex-column bg-light">
          <div style={{ fontSize: "20px", fontWeight: "600", color: "#0D87E8" }}>معماری</div>
          <div
            dir="rtl"
            style={{
              color: "#737B8C",
              lineHeight: "32px",
              fontWeight: "600",
              marginTop: "15px",
            }}
          >
            انجام پروژه های معماری با نرم افزار های 3Dmax , AutoCad,Revit
            <br />
            مدلسازی فضاهای داخلی و خارجی و سایت و پلان و رندر توسط Vray
            <br />
            انجام اسکیس و راندو و شیب بندی با انواع وسایل طراحی
            <br />
            مطالعات اولیه و مطالعات کامل طرح مورد نظر
            <br />
            رسم نقشه شماتیک و پیش بینی هزینه های اولیه طرح
          </div>
          <Link to="architect" className="btn w-15 me-auto btn-outline-primary fw-bold mt-auto">
            ادامه مطلب
          </Link>
        </div>
        <div className="w-50 w-100res d-flex flex-column justify-content-start align-items-start" id="childw10011">
          <img src={it1} width="100%" height="380px" alt="Logo" id="shadowimage" />
        </div>
      </div>
      <div className="w-75 mb-3 rounded-bottom shadow d-flex flex-row " style={{ backgroundColor: "#F6F7F9" }} id="architectContainerchild">
        <div className="w-50 w-100res d-flex flex-column justify-content-start rounded align-items-start" id="childw10011">
          <img src={it3} width="100%" height="380px" style={{ borderBottomLeftRadius: "6px" }} alt="Logo" id="shadowimage" />
        </div>
        <div id="w-100res" className="w-50 text-end p-4 d-flex flex-column ">
          <div style={{ fontSize: "20px", fontWeight: "600", color: "#0D87E8" }}>مکانیک</div>
          <div
            style={{
              color: "#737B8C",
              lineHeight: "32px",
              fontWeight: "600",
              marginTop: "15px",
            }}
          >
            قبول سفارشات و خدمات فنی مهندسی و نقشه کشی
            <br />
            ساخت و تولید انواع قطعات و تجهیزات
            <br />
            شبیه سازی های فنی و تخصصی با نرم افزار های تحلیلی
            <br />
            بازرسی و کنترل کیفیت قطعات
          </div>
          <Link to="mecanic" className="btn w-15 me-auto btn-outline-primary fw-bold mt-auto">
            ادامه مطلب
          </Link>
        </div>
      </div>
    </div>
  );
};

export default R_and_D;
