import React from "react";
import { Link } from "react-router-dom";
import it1 from "../images/mecanic/mecanicTolid (2).jpg";
import it2 from "../images/mecanic/mecanicTolid.jpg";

import Slider from "react-slick";

const Mecanic1 = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 1000,
    // centerMode: true,
    // centerPadding: "60px",
    //     rows: 2,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <div style={{ marginTop: "35vh" }} className="w-100 d-flex justify-content-center" id="itContainer">
      <div className="w-75 p-3 my-5 rounded shadow justify-content-center align-items-center d-flex flex-column text-dark" dir="ltr" style={{ backgroundColor: "#F6F7F9" }} id="itContainerchild">
        <Link
          to="/R&D/mecanic/"
          className="p-2 mb-auto position-relative rounded-circle border-info text-light bg-dark text-center d-flex justify-content-center align-items-center ms-auto"
          style={{ width: "35px", height: "35px" }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi mt-1 bi-arrow-right" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
          </svg>
        </Link>
        <div className="fa-it fs-5">ساخت و تولید انواع قطعات و تجهیزات</div>
        <div className="d-flex flex-column mx-auto justify-content-center align-items-center w-100 mt-5">
          <div className="w-100  mb-5 fw-bold text-secondary text-center lh-lg d-flex justify-content-center my-auto ">
            مهندسی معکوس و تهیه دفترچه مشخصات فنی قطعات و تجهیزات ساخت و تولید انواع قطعات با کیفیت و استاندارد های روز دنیا
          </div>
          <div className="w-95 d-flex justify-content-center align-items-center">
            <Slider {...settings} className="w-100 mb-5">
              <div className="w-85 ">
                <img src={it1} className="rounded" width="100%" height="240px" alt="Logo" />
              </div>
              <div className="w-85 mx-3 ">
                <img src={it2} className="rounded" width="100%" height="240px" alt="Logo" />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mecanic1;
