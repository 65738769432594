import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const Footer = () => {
  // ایجاد یک متغیر وضعیت برای نگهداری ایمیل ورودی
  const [email, setEmail] = useState("");
  // ایجاد یک متغیر وضعیت برای نگهداری پاسخ سرور
  const [response, setResponse] = useState(null);

  // تابع برای ارسال اطلاعات به سرور با استفاده از axios
  const handleSubmit = async () => {
    try {
      // ارسال درخواست post به آدرس مورد نظر با داده‌های مربوط به ایمیل
      const res = await axios.post("https://tlh.co.ir/api/v01/newsletter.php", {
        email: email,
      });
      // بررسی مقدار status در پاسخ سرور
      if (res.data.status === "ok") {
        // تنظیم متغیر وضعیت response به مقدار "ثبت شد"
        setResponse("ثبت شد");
      } else if (res.data.status === "error") {
        // تنظیم متغیر وضعیت response به مقدار "خطا"
        setResponse("خطا");
      }
    } catch (err) {
      // در صورت بروز خطا، نمایش پیغام خطا در کنسول
      console.error(err);
    }
  };

  return (
    <div className="w-100 py-10 d-flex justify-content-center align-items-center" id="footerbg" dir="rtl">
      <div className="w-60 d-flex flex-row justify-content-start align-items-start" id="test">

        <div className="d-flex flex-column align-items-end" dir="ltr">

          <div id="footericon" className="my-3 w-100 d-flex flex-row justify-content-end w-40 align-items-center">
            <div className="mx-3 text-nowrap " id="footerhead">
              طلوع لوتوس هیرکان
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="currentColor" className="bi bi-globe-americas" viewBox="0 0 16 16">
              <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM2.04 4.326c.325 1.329 2.532 2.54 3.717 3.19.48.263.793.434.743.484-.08.08-.162.158-.242.234-.416.396-.787.749-.758 1.266.035.634.618.824 1.214 1.017.577.188 1.168.38 1.286.983.082.417-.075.988-.22 1.52-.215.782-.406 1.48.22 1.48 1.5-.5 3.798-3.186 4-5 .138-1.243-2-2-3.5-2.5-.478-.16-.755.081-.99.284-.172.15-.322.279-.51.216-.445-.148-2.5-2-1.5-2.5.78-.39.952-.171 1.227.182.078.099.163.208.273.318.609.304.662-.132.723-.633.039-.322.081-.671.277-.867.434-.434 1.265-.791 2.028-1.12.712-.306 1.365-.587 1.579-.88A7 7 0 1 1 2.04 4.327Z" />
            </svg>
          </div>
          <div className="text-wrap text-end w-75" id="footertitle">
            گرگان , میدان مفتح , ابتدای کمربندی , کارخانه نوآوری گرگان
          </div>
          <div className="text-wrap text-end mt-2 w-75" id="footertitle">
          گرگان , خیابان امام خمینی , روبه رو هتل خیام , آفتاب ۱۴
          </div>
        </div>
        
        <div className="d-flex flex-row mx-auto " id="footerl">
          <div className="d-flex flex-column align-items-start " id="footerlists">
            <div className="my-3 d-flex flex-row justify-content-center align-items-center">
              <div className=" text-nowrap" id="footerhead">
                طلوع لوتوس هیرکان
              </div>
            </div>
            <Link to="it" className="text-wrap text-end text-light" id="footertitle">
              برنامه نویسی
            </Link>
            <Link to="R&D/electronic" className="text-wrap text-end text-light" id="footertitle">
              الکترونیک
            </Link>
            <Link to="R&D/mecanic" className="text-wrap text-end text-light" id="footertitle">
              مکانیک
            </Link>
            <Link to="R&D/architect" className="text-wrap text-end text-light" id="footertitle">
              معماری
            </Link>
            <div className="text-wrap text-end " id="footertitle">
              آموزش
            </div>
          </div>

          <div className="d-flex flex-column align-items-start mx-5" id="footerlists">
            <div className="my-3 d-flex flex-row justify-content-center align-items-center">
              <Link to="hosting" className=" text-nowrap text-light" id="footerhead">
                مرکز داده
              </Link>
            </div>
            <Link to="hosting" className="text-nowrap text-end text-light" id="footertitle">
              فروش هاست
            </Link>
            <Link to="hosting" className="text-nowrap text-end text-light" id="footertitle">
              Co-Location
            </Link>
            <Link to="hosting" className="text-wrap text-end text-light" id="footertitle">
              ثبت دامنه
            </Link>
            <Link to="hosting" className="text-wrap text-end text-light" id="footertitle">
              VPS داخلی
            </Link>
            <Link to="hosting" className="text-nowrap text-end text-light" id="footertitle">
              Cloud Server
            </Link>
         
        </div>
        <div className="d-flex flex-column align-items-start mx-5" id="lastcontainer">
          <div className="my-3 d-flex flex-row justify-content-center align-items-center">
            <div className=" text-nowrap" id="footerhead">
              خبرنامه
            </div>
          </div>
          <div className="text-wrap text-end " id="footertitle">
            برای دریافت آخرین اخبار تکنولوژی , محصولات و خدمات آدرس ایمیل خود را ثبت کنید ...{" "}
          </div>
          {/* شرط برای نمایش یا پنهان کردن قسمت ورودی و دکمه */}
          {!response && (
            <div className="text-wrap text-end mt-3 d-flex flex-row">
              <input
                className="form-control rounded-0 rounded-end"
                placeholder="ایمیل خود را ثبت کنید"
                id="inputdiv"
                // تغییر مقدار email با هر تغییر در ورودی
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                id="inputdiv"
                className="rounded-start border-0 px-3 text-light"
                style={{ backgroundColor: "#0078FF" }}
                // فراخوانی تابع handleSubmit با زدن دکمه ثبت
                onClick={handleSubmit}
              >
                ثبت
              </button>
            </div>
          )}
          {/* نمایش پاسخ سرور در صورت وجود */}
          {response && (
            <div className="text-wrap text-end mt-3 d-flex flex-row justify-content-end">
              {response}
            </div>
          )}
          <div>Copyright © Tlh.co.ir: All rights reserved</div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
